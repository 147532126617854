<template>

  <div>
    <p class="primary--text pt-4">{{ $t('ROLE.INFO') }}</p>
    <v-row class="mt-0">
      <v-col cols="8" class="pb-0 pt-0">
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          required
          :rules="[$rules.maxlength(80)]"
          :label="$t('ROLE.FIELD_TITLE')"
          :placeholder="$t('ROLE.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
      <v-col cols="2" class="pb-0 pt-0">
        <v-text-field
          v-model="level"
          @change="$emit('input', {level: $event})"
          type="number"
          :min="0"
          required
          :label="$t('ROLE.FIELD_LEVEL')"
          :placeholder="$t('ROLE.FIELD_LEVEL_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>
  </div>

</template>

<script>
export default {
  name:"FormRoleCompactIntro",
  data: (_this) => ({
    //
    title: undefined,
    level: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    },
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        if (key === 'date' && typeof values[key] === 'string') values[key] = values[key].slice(0,10)
        this[key] = values[key]
      })
    },


  }
}
</script>
