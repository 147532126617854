<script>

export default {
  name: 'FormRoleMixins',
  data: (_this) => ({
    loading: 0,
    selected: 0,
    showSaveMultiple: false,
    fields: [
      'status',
      'title',
      'level',
      'default_permissions',
    ],
    // database values
    loaded_values: undefined,
    // default values
    default_values: {
      status: undefined,
      title: undefined,
      level: undefined,
      default_permissions: [],
    },
    // current values
    values: {
      status: undefined,
      title: undefined,
      level: undefined,
      default_permissions: [],
    },
  }),
  props: {
    ids: {
      type: [Array, Boolean],
      default: () => ([])
    },
    props: {
      type: [Object, Boolean],
      default: () => ({})
    }
  },
  watch: {
    ids (n,o) {
      if (n && n.length > 0) {
        if (n[this.selected].id !== this.id) {
          this.selected = 0
          this.loadItem()
        }
      }
    }
  },
  computed: {
    canPrev () {
      return this.selected > 0 && this.ids.length > 0
    },
    canNext () {
      return this.selected < this.ids.length-1 && this.ids.length > 0
    },
    id () {
      return this.loaded_values ? this.loaded_values.id : null
    }
  },
  created () {
    if (this.ids.length > 0) {
      this.loadItem()
    } else {
      this.resetItem()
    }
  },
  methods: {

    mapValueToObject (v) {
      return {value: v, text: this.$i18n.t(`job.${v.toUpperCase()}`)}
    },

    // apply changes from another data (child component)
    handleInput (values) {
      values = this.$clone(values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') {
          if (values[field] instanceof Object && !(values[field] instanceof Array)) {
            this.values[field] = Object.assign({}, this.values[field], values[field])
          } else {
            this.values[field] = values[field]
          }
        }
      }
    },


    // init and reset values
    resetItem () {
      const values = this.$clone(this.id ? this.loaded_values : this.default_values)
      for (const field of this.fields) {
        if (typeof values[field] !== 'undefined') this.values[field] = values[field]
      }
    },


    // load prev item on the fly
    prevItem () {
      if (this.canPrev) {
        this.selected--
        return this.loadItem()
      }
    },


    // load next item on the fly
    nextItem () {
      if (this.canNext) {
        this.selected++
        return this.loadItem()
      }
    },


    // load item
    loadItem () {
      this.loading = true
      const _where = {
        id: this.ids[this.selected],
        user_products: true
      }
      const query = {where: _where}
      return this.$services.api_user.role.search(query)
        .then(response => {
          if (response.data && response.data[0]) {
            this.loaded_values = this.$clone(response.data[0])
          }
          return response
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.resetItem()
          this.loading = false
        })

    },


    // save item
  saveItem (multiple = false) {
    if (this.ids.length > 1 && (typeof multiple !== 'boolean' || !multiple)) return this.showSaveMultiple = true
      this.showSaveMultiple = false
      this.loading = true
      if (!this.id) {
        const values = {}
        for (const field of this.fields) {
          if (typeof this.values[field] !== 'undefined') values[field] = this.values[field]
        }
        return this.$services.api_user.role.create({values})
          .then(response => {
            if (response.data) {
              this.$emit('create', response.data[1])
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        const values = this.$compare(this.loaded_values, this.values, this.fields)
        if (Object.keys(values).length > 0) {
          const ids = multiple && this.ids.length > 1 ? this.ids : this.id
          return this.$services.api_user.role.update({values, where: {id: ids}})
            .then(response => {
              if (response.data) {
                this.$emit('update', ids)
              }
            })
            .catch(e => {
              this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      }
    },


    // remove item
    removeItem (multiple = true) {
      const ids = multiple && this.ids.length > 1 ? this.ids : this.id
      if (ids) {
        this.loading = true
        return this.$services.api_user.role.remove({where: {id: ids}})
          .then(response => {
            if (response.data) {
              this.$emit('remove', ids)
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        console.warning('You cannot remove element without its id.')
      }
    },


  }
}
</script>
